export const Outils = {
    data() {
        return {}
    },
    mounted() { },
    methods: {
        /*jePeuxReserver(endroit) {
            if (endroit.equipe) {
                console.log(this.$store.state.user.equipes)
                if (this.$store.state.user.equipes[endroit.equipe]) {
                    return true;
                } else {
                    alert(
                        "La réservation est réservée aux membres de l'équipe " +
                        endroit.equipe
                    );
                }
            }
        },*/
        async getUser(user_id) {
            let response = await this.api.get('users', { params: { clause: { id: user_id } } })
            return response.data.users.pop()
        },
        dateFr(date) {
            return date.toLocaleDateString('fr', { weekday: 'long', month: 'long', day: 'numeric' })
        },
        getWeek(dt = false) {
            if (!dt) {
                dt = new Date();
            }
            var tdt = new Date(dt.valueOf());
            var dayn = (dt.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            var firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4) {
                tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
            }
            return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },
        getLundi() {
            return this.getDay('monday');
        },
        getVendredi() {
            return this.getDay('friday', 'next');
        },
        getDay(dayName, sens = 'prev') {
            let delay = 0;
            if (sens == 'next') {
                delay = 7;
            }
            // The current day
            let date = new Date();
            let now = date.getDay();
            // Days of the week
            let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            // The index for the day you want
            let day = days.indexOf(dayName.toLowerCase());
            // Find the difference between the current day and the one you want
            let diff = day - now;
            diff = diff < 1 ? delay + diff : diff;
            // Get the timestamp for the desired day
            let nextDayTimestamp = date.getTime() + (1000 * 60 * 60 * 24 * diff);
            // Get the next day
            return new Date(nextDayTimestamp);
        },
        ucfirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        jourOuvre(date) {
            var d = new Date(date);
            if (d.getDay() == 0 || d.getDay() == 6) {
                return false;
            }
            return true;
        },
        nomMois(date) {
            let days = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
            var d = new Date(date);
            return days[d.getMonth()];
        },
        nomJour(date) {
            let days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
            var d = new Date(date);
            return days[d.getDay()];
        },
        logOut() {
            this.$store.commit('setSession', false);
            Sso.logOut({ title: 'The Office' });
        },
        heureCourante() {
            let d = new Date()
            let h = d.getHours();
            let m = d.getMinutes();
            let heure = (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m;
            return heure;
        }
    },
    computed: {
        isAdmin() {
            if (this.isConnected) {
                if (this.$store.state.user.admin) {
                    return true;
                }
            }
        },
        isConnected() {
            if (!this.$store.state.session) {
                return false;
            }
            if (this.$store.state.user === false) {
                return false;
            }
            if (Object.keys(this.$store.state.user).length === 0 && this.$store.state.user.constructor === Object) {
                return false;
            }
            return true;
        }
    }
}