<template>
  <div id="app">
    <template v-if="isConnected">
      <template v-if="checked">
        <Navbar />

        <router-view />

        <footer class="footer has-background-white">
          <div class="content has-text-centered">
            <p>
              Un problème ? Une question ? Contactez
              <a
                href="mailto:theoffice@sopress.net"
              >theoffice@sopress.net</a>
            </p>
          </div>
        </footer>
      </template>
    </template>
    <router-view v-else />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";

export default {
  data() {
    return {
      checked: false,
    };
  },
  components: {
    Navbar,
  },
  watch: {
    $route(to, from) {
      this.demarrer();
    },
  },
  mounted() {
    this.demarrer();
  },
  methods: {
    demarrer() {
      setTimeout(() => {
        if (!this.isConnected) {
          Sso.logIn(this.$route.query.jwt, { title: "The Office" }, (data) => {
            this.$store.commit("setSession", data);
            document.location.href = document.location.href.split('jwt')[0]
            // this.$router.go();
          });
        } else {

          this.api.get("endroits-types").then((response) => {
            this.$store.commit("setTypes", response.data["endroits-types"]);
          });
          this.api.get("check-session").then((response) => {
            if (!response.data["check-session"]) {
              this.logOut();
            } else {
              this.checked = true;
            }
          });
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
html {
  height: 100%;
}
body {
  --color-main: #fee100;
  --color-hover: #fee100;
  --color-link-hover: #dddddd;
  --color-link-selected: white;
  min-height: 100%;

  .button.is-warning.is-outlined {
    border-color: var(--color-main);
    color: var(--color-main);
    &:hover {
      background-color: var(--color-main);
    }
  }
}
figure {
  color: #19d6ba;
}
.picto {
  width: 30px;
  max-width: 30px;
}
.logo {
  width: 30px;
  max-width: 30px;
}
</style>