<template>
	<div>
		<div class="container" v-for="endroit in endroits">
			<div class="card">
				<div class="card-header">
					<h3 class="card-header-title is-inline">
						Salle
						<span v-if="endroit.ferme" class="tag is-danger is-pulled-right">Fermée</span>
						<template v-else>
							<span v-if="endroit.disponible" class="tag is-success is-pulled-right">Disponible</span>
							<span v-else class="tag is-danger is-pulled-right">Occupée</span>
						</template>
					</h3>
				</div>
				<div class="card-content">
					<router-link :to="{name:'Endroit',params:{id:endroit.id,slug:endroit.slug}}">
						<h3 class="title">{{endroit.libelle}}</h3>
						<h2 class="subtitle">{{endroit.description}}</h2>
					</router-link>
				</div>
				<div class="card-footer">
					<div class="card-footer-item">
						<template v-if="endroit.ferme">
							<div class="button is-danger">
								Salle fermée à la réservation (<small>{{endroit.message_ferme}}</small>)
							</div>

						</template>
						<template v-else>
							<router-link class="button is-warning" :to="{name:'Endroit',params:{id:endroit.id,slug:endroit.slug}}">
								Voir le planning / Faire une réservation
							</router-link>
						</template>
					</div>
				</div>
			</div>
			<br>
		</div>
	</div>
</template>
<script>

	export default {
		props : ['type'],
		mounted() {
			this.api.get('endroits').then(response => {
				this.$store.commit('setEndroits',response.data.endroits)
			})
		},
		computed : {
			endroits() {
				let ret = [];
				this.$store.state.endroits.forEach(endroit => {
					if(endroit.type == this.type) {
						ret.push(endroit);
					}
				});
				return ret;
			}
		}
	}
</script>
<style lang="scss">
.card {
	&:hover {
		opacity: 0.9;
	}
	a {
		text-decoration: none;
	}
}
</style>