import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	session:false,
  	user:{
      id:false,
      personne_id:false,
  		nom:false,
  		email:false,
      couleurs:false,
      admin:false,
      photo:false,
      equipes:false
  	},
    endroits : [],
  	types : []
  },
  mutations: {
    setTypes(state, types) {
      state.types = types;
    },
  	setEndroits(state, endroits) {
  		state.endroits = endroits;
      console.log(endroits);
  	},
  	setSession(state, data) {
  		state.session=data ? data.session_id : false;
        for (let key in state.user) {
            state.user[key] = data ? data.user[key] : false;
        }

  	}
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
