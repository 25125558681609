<template>
	<div class="home" v-if="isConnected">

			<div class="container">

				<div class="tabs">
					<ul>
						<li v-for="tmpType in $store.state.types" :class="tmpType.slug == type ? 'is-active' : ''">
							<router-link :to="'?type='+tmpType.slug">{{tmpType.libelle}}</router-link>
						</li>
					</ul>
				</div>

				<Endroits :type="type"/>
			</div>

	</div>
</template>

<script>
	import Endroits from '@/components/Endroits.vue'

	export default {
		name: 'Home',
		mounted() {
		},
		computed : {
			type() {
				if(this.$route.query.type){
					return this.$route.query.type;
				} else {
					return 'reunion';
				}
			}
		},
		components: {
			Endroits
		},
	}
</script>
