import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/regles',
    name: 'Regles',
    component: () =>
        import('../views/Regles.vue')
},
{
    path: '/wifi',
    name: 'Wifi',
    component: () =>
        import('../views/Wifi.vue')
},
{
    path: '/tel',
    name: 'Tel',
    component: () =>
        import('../views/Tel.vue')
},

{
    path: '/carte',
    component: () =>
        import('../views/Carte.vue')
},
{
    path: '/activite',
    name: 'Activite',
    component: () =>
        import('../views/Activite.vue')
},
{
    path: '/activite/semaine/:semaine',
    name: 'ActiviteSemaine',
    component: () =>
        import('../views/Activite.vue'),
},
{
    path: '/activite/semaine/:semaine/user/:user_id',
    name: 'ActiviteSemaineUser',
    component: () =>
        import('../views/Activite.vue'),
},
{
    path: '/plan',
    name: 'Plan',
    component: () =>
        import('../views/Plan.vue')
},
{
    path: '/presence',
    name: 'Presence',
    component: () =>
        import('../views/Presence.vue')
},
{
    path: '/endroit/:id/:slug',
    name: 'Endroit',
    component: () =>
        import( /* webpackChunkName: "endroits" */ '../views/Endroit.vue')
},
{
    path: '/endroit/:id/:slug/semaine/:semaine',
    name: 'EndroitSemaine',
    component: () =>
        import( /* webpackChunkName: "endroits" */ '../views/Endroit.vue')
},
{
    path: '/codes/',
    name: 'Codes',
    component: () =>
        import( /* webpackChunkName: "endroits" */ '../views/Codes.vue')
}


]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router