<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <Logo/>
      </router-link>

      <a @click="open=!open" role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbar-mobile">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbar-mobile" class="navbar-menu" :class="{'is-active' : open}">
      <div class="navbar-start">

        <div class="navbar-item has-dropdown is-hoverable">
          <span class="navbar-link">Office</span>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" to="/plan">Plan</router-link>
            <router-link class="navbar-item" to="/tel">Téléphones</router-link>
            <router-link class="navbar-item" to="/codes">Digicodes</router-link>
            <router-link class="navbar-item" to="/wifi">Accès internet</router-link>
            <a class="navbar-item" href="https://sopress.net/imprimantes/" target="_blank">Imprimantes</a>
            <a class="navbar-item" href="https://pages.sopress.net/la-vie-du-bureau" target="_blank">Régles de vie</a>
          </div>
        </div>

        <div v-for="type in $store.state.types" class="navbar-item has-dropdown is-hoverable" v-if="$store.state.endroits">

          <router-link class="navbar-link" to="/">{{type.libelle.replace('Salles de','')}}</router-link>

          <div class="navbar-dropdown">
            <template v-for="endroit in $store.state.endroits">

              <router-link v-if="endroit.type == type.slug" :to="{name:'Endroit',params:{id:endroit.id,slug:endroit.slug}}" class="navbar-item">
                {{endroit.libelle}}
              </router-link>

            </template>
          </div>
        </div>
        <!-- <div class="navbar-item has-dropdown is-hoverable">
         <router-link class="navbar-link" to="/presence">Coronavirus</router-link>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" to="/presence">Planning de présence</router-link>
            <a class="navbar-item" target="_blank" href="https://www.dropbox.com/sh/26zyprvxhcuq9en/AAA4K4JTnLiMLuQPBl5A9yXWa?dl=0">Attestations</a>
          </div>
        </div> -->
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
        

        <router-link class="navbar-item" to="/activite">Activité</router-link>
        
        </div>
        <div class="navbar-item">
          <div class="buttons">

            <router-link class="button is-primary is-small" to="/carte">
              <span class="icon">
                <i class="fad fa-address-card fa-lg"></i>
              </span>
              <span>
                Carte pro
              </span>
            </router-link>

            <button @click="logOut" class="button is-danger is-small is-outlined">
              <span class="icon">
                <i class="fas fa-power-off"></i>
              </span>
              <span>
              Se deconnecter
              </span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </nav>  
</template>
<script>
  export default {
    data() {
      return {
        open:false
      }
    },
    watch:{
      $route (to, from){
        this.open=false
      }
    }

  }
</script>
<style>
.navbar-brand svg{
  width: 150px;
}
.navbar a{
  text-transform: capitalize;
}
</style>