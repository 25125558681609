import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import '../node_modules/bulma/css/bulma.css';
import '../node_modules/@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';
import { Outils } from './mixins/outils'
import VueMatomo from 'vue-matomo'

Vue.mixin(Outils);

Vue.use(VueMatomo, {
	host: 'https://matomo.sopress.net',
	siteId: 16,
	router: router,
	enableLinkTracking: true,
	requireConsent: false,
	trackInitialView: true,
	debug: true
});

Vue.prototype.$bus = new Vue();



Vue.prototype.api = axios.create({
	baseURL: process.env.VUE_APP_API,
	headers: {
		'Authorization': process.env.VUE_APP_SOGEST_SECRET,
	}
});


Vue.prototype.api.interceptors.request.use((config) => {
	if (!config.url.includes('table')) {
		let form_data = new FormData();

		for (var key in config.data) {
			form_data.append(key, typeof config.data[key] == 'object' ? JSON.stringify(config.data[key]) : config.data[key]);
		}
		config.data = form_data;
	}
	if (store.state.session) {
		config.url += '?session_id=' + store.state.session;
	}
	return config;
});


window.backend = axios.create({
	headers: {
		"X-Parse-Application-Id": "Fe1gMpk3yMmGdHIilInFpG4TCZkOW3qol3J2tyRB",
		"X-Parse-REST-API-Key": "gNuqMn93NtYFg1NfEjpRGpJPl15NBut5p7Jeu5lZ",
	},
	baseURL: process.env.VUE_APP_BACKEND_URL

});



Vue.config.productionTip = false

Vue.component('ContentEditable', () => import('@/components/ContentEditable.vue'));
Vue.component('Logo', () => import('@/components/Logo.vue'));
Vue.component('CartePro', () => import('@/components/CartePro.vue'));
Vue.component('Users', () => import('@/components/Users.vue'));
Vue.component('JourPresence', () => import('@/components/JourPresence.vue'));


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
